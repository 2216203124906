import axios from 'axios';
import store from './store';
import * as actions from './store/actions';
const protocol = window.location.protocol;
const domain = window.location.hostname;
const port = window.location.port;

const token = localStorage.getItem('access_token');
axios.defaults.headers.common.Authorization = `Bearer ${token}`;
axios.defaults.headers.common["X-Tenant"] = window.location.host.split('.')[0];
axios.defaults.headers.common["siteUrl"] = `${protocol}//${domain}:${port? port : ""}`;
// axios.defaults.headers.common["Content-Type"] = 'application/json';
// 'X-Tenant': 
axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(actions.authLogout());
    }
    return Promise.reject(error);
  },
);

export default axios;
