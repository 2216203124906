import * as ActionTypes from '../action-types';
import Http from '../../Http';
import {EncryptOrDecrypt} from '../../services/commonServices';

const defaultUser = {
  id: null,
  name: null,
  email: null,
};

const initialState = {
  isAuthenticated: false,
  user: defaultUser,
};

const authLogin = (state, payload) => {
   var EncryptUserData = '';
   var EncryptSettingData = '';
   EncryptUserData = EncryptOrDecrypt(payload.data.response, 'E');
   localStorage.setItem('userinfo', EncryptUserData);
   EncryptSettingData = EncryptOrDecrypt(payload.data.result, 'E');
   localStorage.setItem('settings', EncryptSettingData);
   localStorage.setItem('access_token', payload.data.token);
   localStorage.setItem('user', JSON.stringify(payload.data.response));
  Http.defaults.headers.common.Authorization = `Bearer ${payload.data.token}`;
  const stateObj = Object.assign({}, state, {
    isAuthenticated: true,
    user:payload.data.response,
  });
  return stateObj;
};

const checkAuth = (state) => {
  const stateObj = Object.assign({}, state, {
    isAuthenticated: (localStorage.getItem('access_token') && localStorage.getItem('userinfo')) ? true : false,
    user: JSON.parse(localStorage.getItem('user'))
  });
  //console.log(state.isAuthenticated);
  if (state.isAuthenticated) {
    Http.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  }
  return stateObj;
};

const logout = (state) => {
  localStorage.clear();
  const stateObj = Object.assign({}, state, {
    isAuthenticated: false,
    user: defaultUser,
  });
  return stateObj;
};

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case ActionTypes.AUTH_CHECK:
      return checkAuth(state);
    case ActionTypes.AUTH_LOGOUT:
      return logout(state);
    default:
      return state;
  }
};

export default Auth;
