import * as ActionTypes from "../action-types";
import Http from "../../Http";

const initialState = {
    recruitments: null,
  
};

const RecruitmentReducers = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.RECRUIT_INDEX:
      return Object.assign({}, state, {
        recruitments: payload.data,
      });

      
    default:
      return state;
  }
};

export default RecruitmentReducers;
