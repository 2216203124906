import * as ActionTypes from '../action-types';
import Http from '../../Http';

const defaultUser = {
  id: null,
  name: null,
  email: null,
};

const initialState = {
  branch: null,
  branch_create: null,
  branch_create_error: null,
  dept: null,
  dept_create: null,
  desi: null,
  desi_create: null,
  shifts: null,
  pay_grades: null,
  holidays: null,
  company: null,
  sidebarShow: true,
  weeks: null,
  countries: null,
  states: null,
  currentCompany: null,
  week: null,
  skills: null,
  qualifications: null,
  educationals: null,
  languages: null,
  quotes : null,
  employmentStatus: null,
  financialYears :null,
};

const BranchReducers = (state = initialState, { type, payload = null }) => {
  switch (type) {

    case ActionTypes.CURRENT_COMPANY:
      return Object.assign({}, state, {
        currentCompany: payload,

      });


    case ActionTypes.COUNTRY_LIST:
      return Object.assign({}, state, {
        countries: payload,

      });

    case ActionTypes.STATE_LIST:
      return Object.assign({}, state, {
        states: payload,

      });


    case ActionTypes.SET_SIDEBAR:
      return Object.assign({}, state, {
        sidebarShow: payload,

      });


    case ActionTypes.BRANCH_INDEX:
      return Object.assign({}, state, {
        branch: payload.data,

      });
    case ActionTypes.BRANCH_CREATE:
      return Object.assign({}, state, {
        branch_create: payload.data,
        branch_create_error: null
      });
    case ActionTypes.BRANCH_CREATE_ERROR:

      return Object.assign({}, state, {
        branch_create_error: payload,
        branch_create: null
      });

    case ActionTypes.DEPT_INDEX:
      return Object.assign({}, state, {
        dept: payload.data,

      });

    case ActionTypes.DEPT_CREATE:
      return Object.assign({}, state, {
        dept_create: payload.data,
      });

    case ActionTypes.DES_INDEX:
      return Object.assign({}, state, {
        desi: payload.data,
      });

    case ActionTypes.DES_CREATE:
      return Object.assign({}, state, {
        desi_create: payload.data,
      });
    case ActionTypes.SHIFT_INDEX:
      return Object.assign({}, state, {
        shifts: payload.data,
      });
    case ActionTypes.HOLIDAY_INDEX:
      return Object.assign({}, state, {
        holidays: payload.data,
      });
    case ActionTypes.HOLIDAY_INDEX_BY_BRANCH:
      return Object.assign({}, state, {
        holidaysByBranch: payload.data,
      });

    case ActionTypes.PAY_INDEX:
      return Object.assign({}, state, {
        pay_grades: payload.data,
      });
    case ActionTypes.COMPANY_INDEX:
      return Object.assign({}, state, {
        company: payload.data,
      });
    case ActionTypes.WEEK_INDEX:
      return Object.assign({}, state, {
        week: payload.data,
      });
    case ActionTypes.SKILL_VIEW:
      return Object.assign({}, state, {
        skills: payload.data,
      });

    case ActionTypes.QUALIFICATION_VIEW:
      return Object.assign({}, state, {
        qualifications: payload.data,
      });

    case ActionTypes.EDUCATIONALLEVEL_VIEW:
      return Object.assign({}, state, {
        educationals: payload.data,
      });

    case ActionTypes.LANGUAGES_VIEW:
      return Object.assign({}, state, {
        languages: payload.data,
      });

      case ActionTypes.Quote_VIEW:
        return Object.assign({}, state, {
          quotes: payload.data,
        });

        case ActionTypes.EmploymentStatus_VIEW:
        return Object.assign({}, state, {
          employmentStatus: payload.data,
        });

        case ActionTypes.FinancialYears_VIEW:
          return Object.assign({}, state, {
            financialYears: payload.data,
          });

        

    default:
      return state;
  }
};


export default BranchReducers;
