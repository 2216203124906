import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from './store';
import * as action from './store/actions';
import './scss/style.scss';
import { connect } from "react-redux";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


const lazyLoader = (props) => {

   if (props.isLoading) {
     return (<div className="loader">

       <div className="spinner-border" role="status">
       </div>

     </div>)
   } else {
     return (<div></div>)
   }


 }

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/pages/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const JobPost = React.lazy(() => import('./views/pages/JobPost'));

store.dispatch(action.authCheck());

class App extends Component {



  render() {
    const {props}=this;
    return (
      <Provider store={store}>
      <HashRouter>
      <ToastContainer />
          <React.Suspense fallback={loading}>
          {lazyLoader(props)}
            <Switch>
              <Route exact path="/job-post/:id" name="Job Post" render={props => <JobPost {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/resetpassword/:id" name="Reset Password" render={props => <ResetPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
      </Provider>
    );
  }
}





const mapStateToProps = (state) => {

  //console.log({ state });

  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user:state.Auth.user,
    isLoading: state.loader.isLoading
  }
};


export default connect(mapStateToProps)(React.memo(App))
