import * as ActionTypes from "../action-types";

const initialState = {
  leaveType_view: null,
  leaveRequest_view: null,
  activeLeaveType: null,
  appliedLeaves: null,
  approvedLeaves: null,
  rejectedLeaves: null,
  leaveDetails: null,
};

const LeaveReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.LEAVE_TYPE_VIEW:
      return Object.assign({}, state, {
        leaveType_view: payload.data,
      });
    case ActionTypes.LEAVE_REQUEST_VIEW:
      return Object.assign({}, state, {
        leaveRequest_view: payload.data,
      });
    case ActionTypes.ACTIVE_LEAVE_TYPE:
      return Object.assign({}, state, {
        activeLeaveType: payload.data.result,
      });
    case ActionTypes.APPROVED_LEAVES:
      return Object.assign({}, state, {
        approvedLeaves: payload.data,
      });
    case ActionTypes.REJECTED_LEAVES:
      return Object.assign({}, state, {
        rejectedLeaves: payload.data,
      });
    case ActionTypes.LEAVE_DETAILS:
      return Object.assign({}, state, {
        leaveDetails: payload.data,
      });
    default:
      return state;
  }
};

export default LeaveReducer;
