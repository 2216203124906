module.exports = {
  env: "prod",
  local: {
    appurl: `http://apigateway.laravel.local/#`,
    apiuploads: `http://apigateway.laravel.local`,
    apigateway: `http://apigateway.laravel.local/api`,
    attendance: `http://attendance.laravel.local/api`,
    company: `http://company.laravel.local/api`,
    employee: `http://employee.laravel.local/api`,
    leaves: `http://leaves.laravel.local/api`,
    master: `http://master.laravel.local/api`,
    recruitment: `http://recruitment.laravel.local/api`,
    salary: `http://salary.laravel.local/api`,
    workshift: `http://workshift.laravel.local/api`,
    REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY : "hRmsZoOmLoCaL",
  },
  prod: {
    appurl: `https://api.zoomhrms.com/#`,
    apiuploads: `https://api.zoomhrms.com`,
    apigateway: `https://api.zoomhrms.com/api`,
    attendance: `https://attendance.zoomhrms.com/api`,
    company: `https://company.zoomhrms.com/api`,
    employee: `https://employee.zoomhrms.com/api`,
    leaves: `https://leaves.zoomhrms.com/api`,
    master: `https://master.zoomhrms.com/api`,
    recruitment: `https://recruitment.zoomhrms.com/api`,
    salary: `https://salary.zoomhrms.com/api`,
    workshift: `https://workshift.zoomhrms.com/api`,
    REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY : "hRmsZoOmLiVe",
  },
};