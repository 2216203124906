import React, { useState, createContext } from "react";

const currDate = new Date();
const currYear = currDate.getFullYear();
const currMonth = currDate.getMonth() + 1;
export const LabelContext = createContext();

export const LabelProvider = (props) => {
  const [page, setPage] = useState(0);

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dataForApi, setDataForApi] = useState([]);
  const [employeeAttendance, setEmployeeAttendance] = useState([]);
  const [employeeColumnsAttendance, setEmployeeColumnsAttendance] = useState([]);
  const [currentYear, setCurrentYear] = useState(currYear);
  const [currentMonth, setCurrentMonth] = useState(currMonth);
  const [branch_id, setBranchId] = useState("");
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [selectedPayGroup, setSelectedPayGroup] = useState("all");
  const [branches, setBranches] = useState([]);
  const [payableDays, setPayableDays] = useState([]);
  const [hourlyEmp, setHourlyEmp] = useState([]);
  const [employeeColumnsEarnings, setEmployeeColumnsEarnings] = useState([]);
  const [employeeEarnings, setEmployeeEarnings] = useState([]);
  const [employeeDeductions, setEmployeeDeductions] = useState([]);
  const [currency, setCurrency] = useState("");
  
  const nextPage = () => {
    setPage(page + 1);
  };
  const prevPage = () => {
    setPage(page - 1);
  };
  const changeSelection = (selectedData) => {
    setSelectedEmployee(selectedData);
  };
  const steps = [
    { title: "Select Period & Employees" },
    { title: "Review Pending Requests" },
    { title: "Confirm Attendance" },
    { title: "Preview Payable Days" },
    { title: "Earnings" },
    { title: "Deductions" },
    { title: "Process Run Payroll" }
  ];

  return (
    <LabelContext.Provider
      value={{
        page,
        steps,
        nextPage,
        prevPage,
        changeSelection,
        selectedEmployee,
        setPendingRequests,
        pendingRequests,
        setEmployees,
        employees,
        setDataForApi,
        dataForApi,
        setEmployeeAttendance,
        employeeAttendance,
        setEmployeeColumnsAttendance,
        employeeColumnsAttendance,
        setCurrentYear,
        currentYear,
        setCurrentMonth,
        currentMonth,
        setBranchId,
        branch_id,
        setDepartments,
        departments,
        setDesignations,
        designations,
        setSelectedPayGroup,
        selectedPayGroup,
        setBranches,
        branches,
        setPayableDays,
        payableDays,
        employeeColumnsEarnings,
        setEmployeeColumnsEarnings,
        employeeEarnings,
        setEmployeeEarnings,
        employeeDeductions,
        setEmployeeDeductions,
        setHourlyEmp,
        hourlyEmp,
        setCurrency,
        currency,
      }}
    >
      {props.children}
    </LabelContext.Provider>
  );
};
