import * as ActionTypes from "../action-types";

const initialState = {
  currentOpening: null,
  branchEmployeeCount:null,
  employeeCountForChart:null,
  empl_branch: null,
};

const DashboardReducers = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.CURRENT_OPENING:
      return Object.assign({}, state, {
        currentOpening: payload,
      });
    case ActionTypes.BRANCH_EMPLOYEE_COUNT:
      return Object.assign({}, state, {
        branchEmployeeCount: payload.data,
      });
    case ActionTypes.EMPLOYEE_COUNT_FOR_CHART:
      return Object.assign({}, state, {
        employeeCountForChart: payload.data,
      });
    case ActionTypes.EMPL_BRANCH:
      return Object.assign({}, state, {
        empl_branch: payload.data,
      });

    default:
      return state;
  }
};

export default DashboardReducers;
