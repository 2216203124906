import * as ActionTypes from '../action-types';


/**********COMPANY **************/

export function getCompanyIndex(payload) {
  return {
    type: ActionTypes.COMPANY_INDEX,
    payload,
  };
}

export function getCountryList(payload) {
  return {
    type: ActionTypes.COMPANY_INDEX,
    payload,
  };
}

/**********  BRANCH ***************************/
export function getBranchIndex(payload) {
  return {
    type: ActionTypes.BRANCH_INDEX,
    payload,
  };
}


export function createBranch(payload) {
  return {
    type: ActionTypes.BRANCH_CREATE,
    payload,
  };
}

export function createBranchError(payload) {
  return {
    type: ActionTypes.BRANCH_CREATE_ERROR,
    payload,
  };
}


/*****************DEPARTMENT****************** */

export function createDepartment(payload) {
  return {
    type: ActionTypes.DEPT_CREATE,
    payload,
  };
}


export function getDepartMentIndex(payload) {
  return {
    type: ActionTypes.DEPT_INDEX,
    payload,
  };
}


/*****************DES_CREATE****************** */

export function createDesignation(payload) {
  return {
    type: ActionTypes.DES_CREATE,
    payload,
  };
}


export function getDesignationIndex(payload) {
  return {
    type: ActionTypes.DES_INDEX,
    payload,
  };
}

/*****************SHIFT_CREATE****************** */

export function createShift(payload) {
  return {
    type: ActionTypes.SHIFT_CREATE,
    payload,
  };
}


export function getShiftIndex(payload) {
  return {
    type: ActionTypes.SHIFT_INDEX,
    payload,
  };
}


/*****************SHIFT_CREATE****************** */

export function createpay(payload) {
  return {
    type: ActionTypes.PAY_CREATE,
    payload,
  };
}


export function getpayIndex(payload) {
  return {
    type: ActionTypes.PAY_INDEX,
    payload,
  };
}


export function editPay(payload) {
  return {
    type: ActionTypes.PAY_EDIT,
    payload,
  };
}



/*****************HOLIDAY_CREATE****************** */

export function createHoliday(payload) {
  return {
    type: ActionTypes.HOLIDAY_CREATE,
    payload,
  };
}



export function getHolidayIndex(payload) {
  return {
    type: ActionTypes.HOLIDAY_INDEX,
    payload,
  };
}


export function getHolidayIndexByBranch(payload) {
  return {
    type: ActionTypes.HOLIDAY_INDEX_BY_BRANCH,
    payload,
  };
}


export function editHoliday(payload) {
  return {
    type: ActionTypes.HOLIDAY_EDIT,
    payload,
  };
}


/*****************Work Week****************** */

export function createWeek(payload) {
  return {
    type: ActionTypes.DEPT_CREATE,
    payload,
  };
}


export function weekIndex(payload) {
  return {
    type: ActionTypes.WEEK_INDEX,
    payload,
  };
}


export function getCompany(payload) {
  return {
    type: ActionTypes.CURRENT_COMPANY,
    payload,
  };
}

export function getCountries(payload) {
  return {
    type: ActionTypes.COUNTRY_LIST,
    payload,
  };
}

export function getStates(payload) {
  return {
    type: ActionTypes.STATE_LIST,
    payload,
  };
}


/************Recruitment****************/
export function getRecruitment(payload) {
  return {
    type: ActionTypes.RECRUIT_INDEX,
    payload,
  };
}

/*******************ATTENDANCE**********************/
export function getATTD(payload) {
  return {
    type: ActionTypes.REQATTD_INDEX,
    payload,
  };
}

/*******************EMPL**********************/
export function getEMPL(payload) {
  return {
    type: ActionTypes.EMPL_INDEX,
    payload,
  };
}

export function viewEMPL(payload) {
  return {
    type: ActionTypes.EMPL_VIEW,
    payload,
  };
}


export function getEmployeeByBranch(payload) {
  return {
    type: ActionTypes.EMPL_BY_BRANCH,
    payload,
  };
}

export function getEmployeeBranch(payload) {
  return {
    type: ActionTypes.EMPL_BRANCH,
    payload,
  };
}
export function getEMPLBirthday(payload) {
  return {
    type: ActionTypes.EMPL_BIRTHDAY,
    payload,
  };
}



export function setAsideComponent(payload) {
  return {
    type: ActionTypes.SET_ASIDE_COMPONENT,
    payload,
  };
}

export function editRow(payload) {
  return {
    type: ActionTypes.EDIT_ROW,
    payload,
  };
}

export function getAgencyDropdown(payload) {
  return {
    type: ActionTypes.GET_AGENCY_DROPDOWN,
    payload,
  };
}

export function getAgentDropdown(payload) {
  return {
    type: ActionTypes.GET_AGENT_DROPDOWN,
    payload,
  };
}

export function getNewAgentDropdown(payload) {
  return {
    type: ActionTypes.GET_NEW_AGENT_DROPDOWN,
    payload,
  };
}
export function getAgencyCode(payload) {
  return {
    type: ActionTypes.GET_AGENCY_CODE,
    payload,
  };
}

export function getSearchPolicyForTransferBook(payload) {
  return {
    type: ActionTypes.GET_SEARCH_POLICY,
    payload,
  };
}

export function transferBook(payload) {
  return {
    type: ActionTypes.TRANSFER_BOOK,
    payload,
  };
}

export function getTransfrBookReason(payload) {
  return {
    type: ActionTypes.GET_REASON,
    payload,
  };
}



/*******************Leave Management**********************/

export function getLeaveType(payload) {
  return {
    type: ActionTypes.LEAVE_TYPE_VIEW,
    payload,
  };
}

export function getActiveLeaveTypes(payload) {
  return {
    type: ActionTypes.ACTIVE_LEAVE_TYPE,
    payload,
  };
}


export function getLeaveDetails(payload) {
  return {
    type: ActionTypes.LEAVE_DETAILS,
    payload,
  };
}

/*******************Leave Request Management**********************/

export function getLeaveRequests(payload) {
  return {
    type: ActionTypes.LEAVE_REQUEST_VIEW,
    payload,
  };
}

export function getApprovedLeaves(payload) {
  return {
    type: ActionTypes.APPROVED_LEAVES,
    payload,
  };
}

export function getRejectedLeave(payload) {
  return {
    type: ActionTypes.REJECTED_LEAVES,
    payload,
  };
}

/*******************Report Managers**********************/

export function ReportManagers(payload) {
  return {
    type: ActionTypes.REPORT_MANAGERS,
    payload,
  };
}


/*******************Salary Management**********************/

export function getSalaryHeadByBranch(payload) {
  return {
    type: ActionTypes.SALARY_HEAD_BY_BRANCH,
    payload,
  };
}

export function getPayGradesByBranch(payload) {
  return {
    type : ActionTypes.PAY_GRADES_BY_BRANCH,
    payload
  }
}
/*******************Salary Management**********************/


/******************* Skill Managment ***********************/

export function getSkill(payload) {
  return {
    type: ActionTypes.SKILL_VIEW,
    payload,
  };
}

/******************* Skill Managment ***********************/



/******************* Qualification Managment ***********************/
export function getQualification(payload) {
  return {
    type: ActionTypes.QUALIFICATION_VIEW,
    payload,
  };
}
/******************* Qualification Managment ***********************/

/******************* EducationalLevel Managment ***********************/
export function getEducationalLevel(payload) {
  return {
    type: ActionTypes.EDUCATIONALLEVEL_VIEW,
    payload,
  };
}
/******************* EducationalLevel Managment ***********************/

/******************* Languages Managment ***********************/
export function getLanguages(payload) {
  return {
    type: ActionTypes.LANGUAGES_VIEW,
    payload,
  };
}
/******************* Languages Managment ***********************/


/******************* Languages Managment ***********************/
export function getCountry(payload) {
  return {
    type: ActionTypes.Country_VIEW,
    payload,
  };
}
/******************* Languages Managment ***********************/

/******************* Quote Managment ***********************/
export function getQuotes(payload) {
  return {
    type: ActionTypes.Quote_VIEW,
    payload,
  };
}
/******************* Quote Managment ***********************/


/******************* Employment Status Managment ***********************/
export function getEmploymentStatus(payload) {
  return {
    type: ActionTypes.EmploymentStatus_VIEW,
    payload,
  };
}
/******************* Employment Status Managment ***********************/


/******************* Employment Status Managment ***********************/
export function getFinancialYears(payload) {
  return {
    type: ActionTypes.FinancialYears_VIEW,
    payload,
  };
}
/******************* Employment Status Managment ***********************/


/******************* Application Logs ***********************/
export function getAppLogs(payload) {
  return {
    type: ActionTypes.GET_APPLOGS,
    payload,
  };
}
/******************* Application Log  ***********************/


