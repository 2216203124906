import * as ActionTypes from "../action-types";

const initialState = {
  employees: null,
  empl_view:null,
  empl_by_branch:null,
  empl_branch: null,
  birthdays: null,
};

const EmployeeReducers = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.EMPL_INDEX:
      return Object.assign({}, state, {
        employees: payload,
      });

      case ActionTypes.EMPL_VIEW:
        return Object.assign({}, state, {
          empl_view: payload.data,
        });
        case ActionTypes.EMPL_BY_BRANCH:
          return Object.assign({}, state, {
            empl_by_branch: payload.data,
          });
    case ActionTypes.EMPL_BRANCH:
      return Object.assign({}, state, {
        empl_branch: payload.data,
      });
      case ActionTypes.EMPL_BIRTHDAY:
        return Object.assign({}, state, {
          birthdays: payload.data,
        });

    default:
      return state;
  }
};

export default EmployeeReducers;
