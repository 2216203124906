import Http from "../Http";
import * as common from "../store/actions/common";
import * as action from "../store/actions";
import config from "./config";
const employeeApiUrl = config[config.env]["employee"];
const ENCRYPTION_KEY = config[config.env]["REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY"];

export function reportManagers(id, callback) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(action.loader(true));
      Http.get(employeeApiUrl + "/employee/get-report-to/"+id, {}, {})
        .then((res) => {
          const result = res.data.data.result.map((items) => {
            const obj = {
              label: `${items.first_name} ${items.last_name}`,
              value: items.id,
            };
            return obj;
          });
          dispatch(common.ReportManagers(result));
          dispatch(action.loader(false));
          return resolve();
        })
        .catch((err) => {
          console.log(err);
        });
    });
}

export function EncryptOrDecrypt(data, condition = 'E') {
	var CryptoJS = require("crypto-js");
	var key = ENCRYPTION_KEY;
	var returnData = '';
  if(data) {
    if(condition == 'E') {
      
      returnData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    }
    
    if(condition == 'D') {
      var bytes = CryptoJS.AES.decrypt(data, key);
      returnData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
	return returnData;
}
export function amountFormat(amount) {
  return (Math.round(amount * 100) / 100).toFixed(2);
}
