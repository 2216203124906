import * as ActionTypes from "../action-types";

const initialState = {
  salary_heads_by_branch: [],
  paygrades_by_branch: [],
};

const SalaryReducers= (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.SALARY_HEAD_BY_BRANCH:
      return Object.assign({}, state, {
        salary_heads_by_branch: payload.data,
      });
    case ActionTypes.PAY_GRADES_BY_BRANCH:
      return Object.assign({}, state, {
        paygrades_by_branch: payload.data,
      });
    default:
      return state;
  }
};

export default SalaryReducers;
