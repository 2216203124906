import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Auth from './Auth';
import persistStore from './persistStore';
import loader from './loader';
import common from './common';
import BranchReducers from './BranchReducers';
import EmployeeReducers from './EmployeeReducers';
import AttendanceReducers from './AttendanceReducers';
import RecruitmentReducers from './RecruitmentReducers';
import leaveReducer from "./leaveReducer"
import SalaryReducers from "./salaryReducer"
import DashboardReducers from './DashboardReducers';

export const history = createBrowserHistory();
const RootReducer = combineReducers({
  router: connectRouter(history),
  Auth,
  BranchReducers,
  EmployeeReducers,
  RecruitmentReducers,
  persistStore,
  loader,
  common,
  leaveReducer,
  SalaryReducers,
  DashboardReducers,
  AttendanceReducers,

});

export default RootReducer;
