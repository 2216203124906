import * as ActionTypes from "../action-types";

const initialState = {
  requestedAttendence: null,
};

const AttendanceReducers = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case ActionTypes.REQATTD_INDEX:
      return Object.assign({}, state, {
        requestedAttendence: payload.data,
      });

    default:
      return state;
  }
};

export default AttendanceReducers;
